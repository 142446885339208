import React from 'react';
import { graphql, PageRendererProps } from 'gatsby';
import Layout from '../components/Layout/Layout';
import { PropertyTypeListingPageQuery } from '../../gatsby-graphql';
import PropertiesListingDiscovery from '../components/PropertiesListingDiscovery/PropertiesListingDiscovery';

interface IPropertyTypeListingTemplateProps extends PageRendererProps {
  data: PropertyTypeListingPageQuery;
  pageContext: {
    operationTypeSlug: string;
    propertyTypeSlug: string;
    propertyTypeName: string;
    operationTypeListingName: string;
  };
}

const PropertyTypeListingTemplate: React.FC<IPropertyTypeListingTemplateProps> = ({
  data: {
    navigation,
    footer,
    properties,
    propertiesListingConfig,
    propertyLocations,
    propertySpecs,
  },
  pageContext: { operationTypeListingName, propertyTypeName, propertyTypeSlug },
}) => (
  <Layout navigation={navigation} footer={footer}>
    <PropertiesListingDiscovery
      {...{
        propertyLocations,
        propertyTypeName,
        operationTypeListingName,
      }}
      propertiesListingConfig={propertiesListingConfig.nodes[0]}
      properties={properties.nodes}
      propertySpecs={propertySpecs.nodes}
      totalProperties={properties.totalCount}
      activePropertyType={{ label: propertyTypeName, value: propertyTypeSlug }}
    />
  </Layout>
);

export const pageQuery = graphql`
  query PropertyTypeListingPage(
    $operationTypeId: String!
    $propertyTypeId: String!
  ) {
    ...SiteNavigation
    ...PageListingFilters
    properties: allContentfulProperty(
      sort: { fields: [createdAt] }
      filter: {
        operationType: { id: { eq: $operationTypeId } }
        propertyType: { id: { eq: $propertyTypeId } }
      }
    ) {
      totalCount
      nodes {
        ...PropertyCard
      }
    }
  }
`;

export default PropertyTypeListingTemplate;
